var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"text-center"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" MikroBilling ")])]),_c('p',{staticClass:"mb-1 text-center"},[_vm._v(" Join our MikroBilling Network 🚀 ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Person Name","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required","vid":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-username","state":errors.length > 0 ? false:null,"name":"register-username","placeholder":"Type Person Name"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Mobile Number","label-for":"register-mobile"}},[_c('validation-provider',{attrs:{"name":"Mobile","rules":"required","vid":"mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VuePhoneNumberInput',{attrs:{"id":"mobile","clearable":"","default-country-code":"BD"},on:{"update":_vm.onUpdate},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"reset-password-confirm"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","state":errors.length > 0 ? false:null,"type":_vm.password2FieldType,"name":"reset-password-confirm","placeholder":"············"},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.settingInfo.is_coupon_active == 1)?_c('b-form-group',{attrs:{"label":"Coupon Code","label-for":"coupon-code"}},[_c('validation-provider',{attrs:{"name":"coupon-code","vid":"coupon-code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"coupon-code","state":errors.length > 0 ? false:null,"name":"coupon_code","placeholder":"Please Enter Valid Coupon Code"},model:{value:(_vm.coupon_code),callback:function ($$v) {_vm.coupon_code=$$v},expression:"coupon_code"}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.couponCodeCheck}},[_vm._v(" Apply ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.settingInfo.is_isp_registration == 1)?_c('b-button',{attrs:{"disabled":invalid,"block":"","type":"submit","variant":"primary"}},[_vm._v(" Pay Now $ "+_vm._s((_vm.couponInfo && _vm.couponInfo.discount) ? _vm.settingInfo.registration_amount - _vm.couponInfo.discount : _vm.settingInfo.registration_amount)+" ")]):_vm._e()],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }